import HttpClient from 'src/common/HttpClient'

import { storeTokenInLocalStorage, storeUserInLocalStorage, storeClientInLocalStorage, removeRefreshTokenSirisInCookie } from '../common/common'
import { getClientByUserId } from 'src/actions/ClientActions'
import { persistor, store } from 'src/app/store'
import { resetPageState } from 'src/features/pageConfig/pageConfigSlice'

export const getRoles = async (signal) => {
  const params = {
    url: 'auth/roles',
    ...signal
  }

  const response = await HttpClient.get(params, true)
  return response
}

export const createUser = (payload) => {
  const params = {
    url: 'auth/register',
    data: payload,
  }

  return HttpClient.post(params)
}

export const login = async (payload) => {

  const params = {
    url: 'auth/login',
    data: payload,
  }
  const response = await HttpClient.post(params, false)
  let clientResponse = null

  if (response?.user && response.auth) {
    storeUserInLocalStorage(response.user)
    clientResponse = await getClientByUserId({ id: response.user.iduser })
    if (clientResponse) storeClientInLocalStorage(clientResponse)
  }
  return { ...response, clientResponse }
}

export const loginWith2fACode = async (payload) => {

  const params = {
    url: 'auth/login-2fa',
    data: payload,
  }
  const response = await HttpClient.post(params, false)
  let clientResponse = null

  if (response?.user && response.auth) {
    storeUserInLocalStorage(response.user)
    clientResponse = await getClientByUserId({ id: response.user.iduser })
    if (clientResponse) storeClientInLocalStorage(clientResponse)
  }
  return { ...response, clientResponse }
}


export const definePassword = async (payload) => {

  const params = {
    url: 'auth/define-password',
    data: payload,
  }
  const response = await HttpClient.put(params)
  const responseData = response

  if (responseData?.token) storeTokenInLocalStorage(responseData.token)

  if (responseData?.user) {
    storeUserInLocalStorage(responseData.user)
    const clientResponse = await getClientByUserId({ id: responseData.user.iduser })
    if (clientResponse) storeClientInLocalStorage(clientResponse)
  }
  return responseData
}


export const logout = async (callAPi = true) => {

  try {
    if (callAPi) {
      let state = false
      const resLogout = await HttpClient.get({ url: 'auth/logout' })
      if (resLogout && !resLogout?.auth) {
        store.dispatch(resetPageState())
        await persistor.purge()
        localStorage.clear()
        removeRefreshTokenSirisInCookie()
        state = true
      } return state
    } else {
      store.dispatch(resetPageState())
      await persistor.purge()
      localStorage.clear()
      removeRefreshTokenSirisInCookie()

      document.location.href = '/'
    }

  } catch (e) {
    return false
  }

}

export const requestPasswordRecovery = async (payload) => {

  const params = {
    url: 'auth/request-password-recovery',
    data: payload,
  }
  const response = await HttpClient.post(params)
  const responseData = response

  return responseData
}

export const resetPassword = async (payload) => {

  const params = {
    url: 'auth/reset-password',
    data: payload,
  }
  const response = await HttpClient.post(params)
  const responseData = response

  return responseData
}

export const refreshToken = async () => {
  return await HttpClient.refreshToken()
}

