import { CBadge } from '@coreui/react';
import React from 'react';


export const SERVER_BASE_URL = 'http://localhost:3001'
// export const SERVER_BASE_URL = 'https://siris-conformite.jcloud-ver-jpc.ik-server.com'
export const API_BASE_URL = SERVER_BASE_URL + '/api/'
export const BASE_URL = 'http://localhost:3000'
// export const BASE_URL = 'https://siris-conformite.jcloud-ver-jpc.ik-server.com'

export const SPACE = {
  'SUPER': 'Super',
  'CUSTOMER_ADMIN': 'Customer Admin',
  'CUSTOMER_Employee': 'Customer Employee',
}

export const STATUT_EXIGENCE = {
  'EN_VIGUEUR': 'En vigueur',
  'A_VENIR': 'A venir',
  'ABROGEE': 'Abrogé'
}

export const STATUT_EXIGENCE_BADGE = {
  'En vigueur': <CBadge color="success">En vigueur</CBadge>,
  'A venir': <CBadge color="warning">À venir</CBadge>,
  'Abrogé': <CBadge color="dark">Abrogé</CBadge>
}

export const ROLE_CLIENT = {
  'ADMINISTRATEUR': 'Administrateur',
  'MEMBRE_D_EQUIPE': 'Membre d\'équipe',
  'RESPONSABLE': 'Responsable'
}

export const PERMISSION_KEY = {
  read: 'permission_read',
  create: 'permission_create',
  update: 'permission_update',
  delete: 'permission_delete',
  admin: 'permission_admin'
}

export const PERMISSION_KEY_LABEL = {
  [PERMISSION_KEY.read]: 'Lecture',
  [PERMISSION_KEY.create]: 'Création',
  [PERMISSION_KEY.update]: 'Écriture',
  [PERMISSION_KEY.delete]: 'Suppression',
  [PERMISSION_KEY.admin]: 'Administration'
}

export const PROPERTY_NAME_ACCESS = {
  REQUIREMENT: {
    title: 'Exigences',
    read: 'requirement_permission_read',
    create: 'requirement_permission_create',
    update: 'requirement_permission_update',
    delete: 'requirement_permission_delete',
    admin: 'requirement_permission_admin',
    module: 'Conformité'
  },
  MY_REQUIREMENT: {
    title: 'Mes exigences',
    read: 'my_requirement_permission_read',
    create: 'my_requirement_permission_create',
    update: 'my_requirement_permission_update',
    delete: 'my_requirement_permission_delete',
    admin: 'my_requirement_permission_admin',
    module: 'Conformité'
  },
  PROCESS: {
    title: 'Processus',
    read: 'process_permission_read',
    create: 'process_permission_create',
    update: 'process_permission_update',
    delete: 'process_permission_delete',
    admin: 'process_permission_admin',
    module: 'Conformité'
  },
  PROCESS_EXIGENCE: {
    title: 'Processus & Exigences',
    read: 'process_exigence_permission_read',
    create: 'process_exigence_permission_create',
    update: 'process_exigence_permission_update',
    delete: 'process_exigence_permission_delete',
    admin: 'process_exigence_permission_admin',
    module: 'Conformité'
  },
  PERSONAL_INFO: {
    title: 'Informations personnelles',
    read: 'personal_info_permission_read',
    create: 'personal_info_permission_create',
    update: 'personal_info_permission_update',
    delete: 'personal_info_permission_delete',
    admin: 'personal_info_permission_admin',
    module: 'Conformité'
  },
  SUBSCRIPTION: {
    title: 'Abonnements',
    read: 'subscription_permission_read',
    create: 'subscription_permission_create',
    update: 'subscription_permission_update',
    delete: 'subscription_permission_delete',
    admin: 'subscription_permission_admin',
    module: 'Paramètres'
  },
  SETTINGS_INCIDENT_TYPE: {
    title: 'Types d\'incidents',
    read: 'settings_incident_type_permission_read',
    create: 'settings_incident_type_permission_create',
    update: 'settings_incident_type_permission_update',
    delete: 'settings_incident_type_permission_delete',
    admin: 'settings_incident_type_permission_admin',
    module: 'Paramètres'
  },
  SETTINGS_INFORMATION_SOURCE: {
    title: 'Sources d\'information',
    read: 'settings_information_source_permission_read',
    create: 'settings_information_source_permission_create',
    update: 'settings_information_source_permission_update',
    delete: 'settings_information_source_permission_delete',
    admin: 'settings_information_source_permission_admin',
    module: 'Paramètres'
  },
  SETTINGS_LOCATION: {
    title: 'Lieux',
    read: 'settings_location_permission_read',
    create: 'settings_location_permission_create',
    update: 'settings_location_permission_update',
    delete: 'settings_location_permission_delete',
    admin: 'settings_location_permission_admin',
    module: 'Paramètres'
  },
  SETTINGS_EQUIPMENT: {
    title: 'Equipements',
    read: 'settings_equipment_permission_read',
    create: 'settings_equipment_permission_create',
    update: 'settings_equipment_permission_update',
    delete: 'settings_equipment_permission_delete',
    admin: 'settings_equipment_permission_admin',
    module: 'Paramètres'
  },
  SETTINGS_ROLE: {
    title: 'Rôles',
    read: 'settings_role_client_permission_read',
    create: 'settings_role_client_permission_create',
    update: 'settings_role_client_permission_update',
    delete: 'settings_role_client_permission_delete',
    admin: 'settings_role_client_permission_admin',
    module: 'Paramètres'
  },
  SETTINGS_DEPARTMENT: {
    title: 'Département',
    read: 'settings_company_department_permission_read',
    create: 'settings_company_department_permission_create',
    update: 'settings_company_department_permission_update',
    delete: 'settings_company_department_permission_delete',
    admin: 'settings_company_department_permission_admin',
    module: 'Paramètres'
  },
  SETTINGS_CATEGORY_RISK: {
    title: 'Catégories de risques',
    read: 'settings_category_risk_permission_read',
    create: 'settings_category_risk_permission_create',
    update: 'settings_category_risk_permission_update',
    delete: 'settings_category_risk_permission_delete',
    admin: 'settings_category_risk_permission_admin',
    module: 'Paramètres'
  },
  SETTINGS_INCIDENT_PRIORITY: {
    title: 'Priorités d\'incidents',
    read: 'settings_incident_priority_permission_read',
    create: 'settings_incident_priority_permission_create',
    update: 'settings_incident_priority_permission_update',
    delete: 'settings_incident_priority_permission_delete',
    admin: 'settings_incident_priority_permission_admin',
    module: 'Paramètres'
  },


  COMPLIANCE_ACTION: {
    title: 'Mise en conformité',
    read: 'compliance_action_permission_read',
    create: 'compliance_action_permission_create',
    update: 'compliance_action_permission_update',
    delete: 'compliance_action_permission_delete',
    admin: 'compliance_action_permission_admin',
    module: 'Conformité'
  },
  EVALUATION_COMPLIANCE_ACTION: {
    title: 'Évaluation actions',
    read: 'evaluation_compliance_action_permission_read',
    create: 'evaluation_compliance_action_permission_create',
    update: 'evaluation_compliance_action_permission_update',
    delete: 'evaluation_compliance_action_permission_delete',
    admin: 'evaluation_compliance_action_permission_admin',
    module: 'Conformité'
  },
  HISTORY: {
    title: 'Historiques',
    read: 'history_permission_read',
    create: 'history_permission_create',
    update: 'history_permission_update',
    delete: 'history_permission_delete',
    admin: 'history_permission_admin',
    module: 'Conformité'
  },
  RISK_MATRIX: {
    title: 'Matrices',
    read: 'risk_matrix_permission_read',
    create: 'risk_matrix_permission_create',
    update: 'risk_matrix_permission_update',
    delete: 'risk_matrix_permission_delete',
    admin: 'risk_matrix_permission_admin',
    module: 'Risques'
  },
  RISK: {
    title: 'Risques',
    read: 'risk_permission_read',
    create: 'risk_permission_create',
    update: 'risk_permission_update',
    delete: 'risk_permission_delete',
    admin: 'risk_permission_admin',
    module: 'Risques'
  },
  RISK_PROCESS: {
    title: 'Processus',
    read: 'risk_process_permission_read',
    create: 'risk_process_permission_create',
    update: 'risk_process_permission_update',
    delete: 'risk_process_permission_delete',
    admin: 'risk_process_permission_admin',
    module: 'Risques'
  },
  RISK_PROCESS_RISK: {
    title: 'Processus et Risques',
    read: 'risk_process_risk_permission_read',
    create: 'risk_process_risk_permission_create',
    update: 'risk_process_risk_permission_update',
    delete: 'risk_process_risk_permission_delete',
    admin: 'risk_process_risk_permission_admin',
    module: 'Risques'
  },
  RISK_INCIDENT: {
    title: 'Incidents',
    read: 'risk_incident_permission_read',
    create: 'risk_incident_permission_create',
    update: 'risk_incident_permission_update',
    delete: 'risk_incident_permission_delete',
    admin: 'risk_incident_permission_admin',
    module: 'Risques'
  },
  RISK_CONTROL: {
    title: 'Contrôles',
    read: 'risk_control_permission_read',
    create: 'risk_control_permission_create',
    update: 'risk_control_permission_update',
    delete: 'risk_control_permission_delete',
    admin: 'risk_control_permission_admin',
    module: 'Risques'
  },
  RISK_EVALUATION: {
    title: 'Evaluations',
    read: 'risk_evaluation_permission_read',
    create: 'risk_evaluation_permission_create',
    update: 'risk_evaluation_permission_update',
    delete: 'risk_evaluation_permission_delete',
    admin: 'risk_evaluation_permission_admin',
    module: 'Risques'
  },
  DOC_DOCUMENT: {
    title: 'Documents',
    read: 'doc_document_permission_read',
    create: 'doc_document_permission_create',
    update: 'doc_document_permission_update',
    delete: 'doc_document_permission_delete',
    admin: 'doc_document_permission_admin',
    module: 'Processus'
  },
  DOC_PROCESS: {
    title: 'Processus',
    read: 'doc_process_permission_read',
    create: 'doc_process_permission_create',
    update: 'doc_process_permission_update',
    delete: 'doc_process_permission_delete',
    admin: 'doc_process_permission_admin',
    module: 'Processus'
  },
  DOC_INDICATOR: {
    title: 'Indicateurs',
    read: 'doc_indicator_permission_read',
    create: 'doc_indicator_permission_create',
    update: 'doc_indicator_permission_update',
    delete: 'doc_indicator_permission_delete',
    admin: 'doc_indicator_permission_admin',
    module: 'Processus'
  },
  DOC_PROCESS_CARD: {
    title: 'Carte processus',
    read: 'doc_process_card_permission_read',
    create: 'doc_process_card_permission_create',
    update: 'doc_process_card_permission_update',
    delete: 'doc_process_card_permission_delete',
    admin: 'doc_process_card_permission_admin',
    module: 'Processus'
  },
  RISK_ACTION: {
    title: 'Mise en conformité',
    read: 'risk_action_permission_read',
    create: 'risk_action_permission_create',
    update: 'risk_action_permission_update',
    delete: 'risk_action_permission_delete',
    admin: 'risk_action_permission_admin',
    module: 'Risques'
  },
  EVALUATION_RISK_ACTION: {
    title: 'Évaluation actions',
    read: 'evaluation_risk_action_permission_read',
    create: 'evaluation_risk_action_permission_create',
    update: 'evaluation_risk_action_permission_update',
    delete: 'evaluation_risk_action_permission_delete',
    admin: 'evaluation_risk_action_permission_admin',
    module: 'Risques'
  },
  HR_PERSON: {
    title: 'Personnes',
    read: 'hr_person_permission_read',
    create: 'hr_person_permission_create',
    update: 'hr_person_permission_update',
    delete: 'hr_person_permission_delete',
    admin: 'hr_person_permission_admin',
    module: 'Ressources humaines'
  },
  HR_JOB_TITLE: {
    title: 'Fonctions',
    read: 'hr_job_title_permission_read',
    create: 'hr_job_title_permission_create',
    update: 'hr_job_title_permission_update',
    delete: 'hr_job_title_permission_delete',
    admin: 'hr_job_title_permission_admin',
    module: 'Ressources humaines'
  },
  HR_SKILL: {
    title: 'Aptitudes',
    read: 'hr_skill_permission_read',
    create: 'hr_skill_permission_create',
    update: 'hr_skill_permission_update',
    delete: 'hr_skill_permission_delete',
    admin: 'hr_skill_permission_admin',
    module: 'Ressources humaines'
  },
  HR_TASK: {
    title: 'Tâches',
    read: 'hr_task_permission_read',
    create: 'hr_task_permission_create',
    update: 'hr_task_permission_update',
    delete: 'hr_task_permission_delete',
    admin: 'hr_task_permission_admin',
    module: 'Ressources humaines'
  },
  HR_COMPLIANCE_BY_PERSON: {
    title: 'Conformité par personne',
    read: 'hr_compliance_by_person_permission_read',
    create: 'hr_compliance_by_person_permission_create',
    update: 'hr_compliance_by_person_permission_update',
    delete: 'hr_compliance_by_person_permission_delete',
    admin: 'hr_compliance_by_person_permission_admin',
    module: 'Ressources humaines'
  },
  HR_COMPLIANCE_BY_TEAM: {
    title: 'Conformité par équipe',
    read: 'hr_compliance_by_team_permission_read',
    create: 'hr_compliance_by_team_permission_create',
    update: 'hr_compliance_by_team_permission_update',
    delete: 'hr_compliance_by_team_permission_delete',
    admin: 'hr_compliance_by_team_permission_admin',
    module: 'Ressources humaines'
  },
  HR_TYPE_INTERESTED_PARTY: {
    title: 'Type de partie intéressée',
    read: 'hr_type_interested_party_permission_read',
    create: 'hr_type_interested_party_permission_create',
    update: 'hr_type_interested_party_permission_update',
    delete: 'hr_type_interested_party_permission_delete',
    admin: 'hr_type_interested_party_permission_admin',
    module: 'Paramètres'
  },
  HR_INTERESTED_PARTY: {
    title: 'Partie intéressée',
    read: 'hr_interested_party_permission_read',
    create: 'hr_interested_party_permission_create',
    update: 'hr_interested_party_permission_update',
    delete: 'hr_interested_party_permission_delete',
    admin: 'hr_interested_party_permission_admin',
    module: 'Paramètres'
  },
  HR_LEARNING_METHOD: {
    title: 'Méthode d\'apprentissage',
    read: 'hr_learning_method_permission_read',
    create: 'hr_learning_method_permission_create',
    update: 'hr_learning_method_permission_update',
    delete: 'hr_learning_method_permission_delete',
    admin: 'hr_learning_method_permission_admin',
    module: 'Paramètres'
  },
  HR_FORMATION: {
    title: 'Formation',
    read: 'hr_formation_permission_read',
    create: 'hr_formation_permission_create',
    update: 'hr_formation_permission_update',
    delete: 'hr_formation_permission_delete',
    admin: 'hr_formation_permission_admin',
    module: 'Ressources humaines'
  },
  HR_SESSION: {
    title: 'Session',
    read: 'hr_session_permission_read',
    create: 'hr_session_permission_create',
    update: 'hr_session_permission_update',
    delete: 'hr_session_permission_delete',
    admin: 'hr_session_permission_admin',
    module: 'Ressources humaines'
  }

};

export const TABLE_NAME_BY_PROPERTY_NAME = {
  REQUIREMENT: {
    tableName: ''
  },
  MY_REQUIREMENT: {
    tableName: ''
  },
  PROCESS: {
    tableName: 'process'
  },
  PROCESS_EXIGENCE: {
    tableName: ''
  },
  PERSONAL_INFO: {
    tableName: ''
  },
  SUBSCRIPTION: {
    tableName: ''
  },
  SETTINGS_INCIDENT_TYPE: {
    tableName: ''
  },
  SETTINGS_INFORMATION_SOURCE: {
    tableName: ''
  },
  SETTINGS_LOCATION: {
    tableName: ''
  },
  SETTINGS_EQUIPMENT: {
    tableName: ''
  },
  SETTINGS_ROLE: {
    tableName: ''
  },
  SETTINGS_DEPARTMENT: {
    tableName: ''
  },
  SETTINGS_CATEGORYs_RISK: {
    tableName: ''
  },

  COMPLIANCE_ACTION: {
    tableName: ''
  },
  EVALUATION_COMPLIANCE_ACTION: {
    tableName: ''
  },
  HISTORY: {
    tableName: ''
  },
  RISK_MATRIX: {
    tableName: ''
  },
  RISK: {
    tableName: 'risk'
  },
  RISK_PROCESS: {
    tableName: 'process'
  },
  RISK_PROCESS_RISK: {
    tableName: ''
  },
  RISK_INCIDENT: {
    tableName: 'incident'
  },
  RISK_CONTROL: {
    tableName: 'control'
  },
  RISK_EVALUATION: {
    tableName: ''
  },
  DOC_DOCUMENT: {
    tableName: 'doc_document'
  },
  DOC_PROCESS: {
    tableName: 'process'
  },
  DOC_INDICATOR: {
    tableName: ''
  },
  DOC_PROCESS_CARD: {
    tableName: ''
  },
  RISK_ACTION: {
    tableName: ''
  },
  EVALUATION_RISK_ACTION: {
    tableName: ''
  },
  HR_PERSON: {
    tableName: ''
  },
  HR_JOB_TITLE: {
    tableName: ''
  },
  HR_SKILL: {
    tableName: ''
  },
  HR_TASK: {
    tableName: ''
  },
  HR_COMPLIANCE_BY_PERSON: {
    tableName: ''
  },
  HR_COMPLIANCE_BY_TEAM: {
    tableName: ''
  },
  HR_FORMATION: {
    tableName: 'hr_formation'
  },
};

export const MENU_NO_CHECK_ACCESS_READ = [
  PROPERTY_NAME_ACCESS.PROCESS.read,
  PROPERTY_NAME_ACCESS.RISK.read,
  PROPERTY_NAME_ACCESS.RISK_PROCESS.read,
  PROPERTY_NAME_ACCESS.RISK_INCIDENT.read,
  PROPERTY_NAME_ACCESS.RISK_CONTROL.read,
  PROPERTY_NAME_ACCESS.DOC_DOCUMENT.read,
  PROPERTY_NAME_ACCESS.DOC_PROCESS.read,
]

export const APPLICABILITY_VERSION = {
  'APPLICABLE': 'applicable',
  'NOTAPPLICABLE': 'notApplicable',
  'NOTDEFINED': 'notDefined'
}

export const APPLICABILITY_VERSION_BADGE = {
  'APPLICABLE': <CBadge color="success">applicable</CBadge>,
  'NOTAPPLICABLE': <CBadge color="danger">non applicable</CBadge>,
  'NOTDEFINED': <CBadge color="info">non défini</CBadge>
}


export const CHECK_POINT_STATUS = {
  'CONFORME': 'compliant',
  'NON_CONFORME': 'notCompliant',
  'NON_APPLICABLE': 'notAppicable',
  'NOT_DEFINED': 'notDefined',
}

export const CHECK_POINT_STATUS_BADGE = {
  'compliant': <CBadge color="success">Conforme</CBadge>,
  'notCompliant': <CBadge color="danger">Non conforme</CBadge>,
  'notAppicable': <CBadge style={{ 'background': 'black' }}>Pas applicable</CBadge>,
  'notDefined': <CBadge color="info">Non défini</CBadge>
}

export const STATUT_COMPLIANCE = {
  'toDo': { label: 'à faire', value: 'toDo', badge: () => <CBadge color="info">à faire</CBadge> },
  'inProgress': { label: 'en cours', value: 'inProgress', badge: () => <CBadge color="primary">en cours</CBadge> },
  'toValidate': { label: 'à valider', value: 'toValidate', badge: () => <CBadge color="success">à valider</CBadge> },
  'correction': { label: 'correction', value: 'correction', badge: () => <CBadge color="danger">correction</CBadge> },
  'closed': { label: 'terminée', value: 'closed', badge: () => <CBadge color="dark">terminée</CBadge> }
}

export const MODULES = {
  'COMPLIANCE': 'compliance',
  'RISK': 'risk',
  'DOCUMENT': 'document',
  'HUMAN_RESOURCES': 'human_resources'

}

export const STATUT_MATRIX = {
  'brouillon': { label: 'Brouillon', value: 'brouillon', badge: () => <CBadge color="danger">Brouillon</CBadge> },
  'utilisable': { label: 'Utilisable', value: 'utilisable', badge: () => <CBadge color="success">Utilisable</CBadge> },
}

export const STATUT_VERSION = {
  'toDo': { label: 'à faire', value: 'toDo', badge: <CBadge color="info">à faire</CBadge> },
  'inProgress': { label: 'en cours', value: 'inProgress', badge: <CBadge color="primary">en cours</CBadge> },
  'toValidate': { label: 'à valider', value: 'toValidate', badge: <CBadge color="success">à valider</CBadge> },
  'correction': { label: 'correction', value: 'correction', badge: <CBadge color="danger">correction</CBadge> },
  'closed': { label: 'terminée', value: 'closed', badge: <CBadge color="dark">terminée</CBadge> }
}

export const STATUT_VERSION_ASSESMENT = {
  'toDo': { label: 'à faire', value: 'toDo', badge: () => <CBadge color="info">à faire</CBadge> },
  'inProgress': { label: 'en cours', value: 'inProgress', badge: () => <CBadge color="primary">en cours</CBadge> },
  'toValidate': { label: 'à valider', value: 'toValidate', badge: () => <CBadge color="success">à valider</CBadge> },
  'closed': { label: 'terminée', value: 'closed', badge: () => <CBadge color="dark">terminée</CBadge> }
}

export const DOC_FILE_TYPE = {
  'URL': 'url',
  'ATTACHMENT': 'attachment',
  'BPMN': 'bpmn'
}

export const RISK_STATUS_ASSESSMENT = {
  'toEvaluate': { label: 'A évaluer', value: 'toEvaluate', badge: () => <CBadge color="danger">A évaluer</CBadge> },
  'evaluated': { label: 'Evalué', value: 'evaluated', badge: () => <CBadge color="success">Evalué</CBadge> }
}

export const STATUT_PERFORMANCE_MONITORING = {
  'new': { label: 'Nouveau', value: 'new', badge: () => <CBadge color="info">Nouveau</CBadge> },
  'started': { label: 'Démarré', value: 'started', badge: () => <CBadge color="primary">Démarré</CBadge> },
  'finished': { label: 'terminée', value: 'finished', badge: () => <CBadge color="dark">terminée</CBadge> }
}

export const TARGET_VALUE_CRITERIA_LIST = [
  'Plus petit ou égal',
  'Plus grand ou égal'
]

export const TARGET_VALUE_CRITERIA = {
  PLUS_PETIT_OU_EGAL: { label: 'Plus petit ou égal', value: -1 },
  PLUS_GRAND_OU_EGAL: { label: 'Plus grand ou égal', value: 1 }
}

export const EVALUATION_PERFORMANCE_MEASUREMENT = {
  'ok': { label: 'OK', value: 'ok', badge: () => <CBadge color="success">OK</CBadge> },
  'nok': { label: 'NOK', value: 'nok', badge: () => <CBadge color="danger">NOK</CBadge> },
}

export const PROCESS_TYPE = {
  top: 'Processus de direction',
  middle: 'Processus opérationnels',
  bottom: 'Processus de support'
}

export const PROCESS_LIST = {
  processus0:
    [
      {
        label: "D1 - Analyser les risques et opportunités",
        processusList: [
        ],
        ref: "1",
        refInTableProcess: "1",
      },
      {
        label: "D2 - Établir les objectifs",
        processusList: [
        ],
        ref: "2",
        refInTableProcess: "2",
      },
      {
        label: "D3 - Assurer la conformité",
        processusList: [
        ],
        ref: "3",
        refInTableProcess: "3",
      }

    ],
  processus1:
    [
      {
        label: "O1 - Réaliser des prestations de conseil",
        processusList: [],
        ref: "6",
        refInTableProcess: "6",
      },
      {
        label: "O2 - Réaliser les prestations d'audit",
        processusList: [],
        ref: "7",
        refInTableProcess: "17",
      },
      {
        label: "O3 - Réaliser les prestations de formation",
        processusList: [],
        ref: "8",
        refInTableProcess: "20"
      },
      {
        label: "O4 - Réaliser les prestations informatiques",
        processusList: [
          { label: "VO 1 - Préparer les votations", ref: "9.1", refInTableProcess: "23" }
        ],
        ref: "9",
        refInTableProcess: "22"
      }
    ],
  processus2:
    [
      {
        label: "S1 - Ressources humaines",
        processusList: [],
        ref: "12",
        refInTableProcess: "27"
      },
      {
        label: "S2 - Ressources IT",
        processusList: [],
        ref: "13",
        refInTableProcess: "37"
      },
      {
        label: "S3 - Gérer les ressources financières",
        processusList: [
        ],
        ref: "14",
        refInTableProcess: "44"
      },

    ]
}

export const INCIDENT_STATUS = {
  'draft': { label: 'Brouillon', value: 'draft', badge: () => <CBadge color="danger">Brouillon</CBadge> },
  'published': { label: 'Publié', value: 'published', badge: () => <CBadge color="success">Publié</CBadge> },
}

export const CHECKPOINT_CONTROL_STATUS = {
  'ok': { label: 'OK', value: 'ok', badge: () => <CBadge color="success">OK</CBadge> },
  'notOk': { label: 'Pas OK', value: 'notOk', badge: () => <CBadge color="danger">Pas OK</CBadge> },
  'notDefined': { label: 'Non défini', value: 'notDefined', badge: () => <CBadge color="info">Non défini</CBadge> },
}

export const CHECKPOINT_CONTROL_ELEMENT_TYPE = {
  'equipment': { label: 'Equipement', value: 'equipment', badge: () => <CBadge color="success">Equipement</CBadge> },
  'document': { label: 'Document', value: 'document', badge: () => <CBadge color="info">Document</CBadge> },
  'process': { label: 'Processus', value: 'process', badge: () => <CBadge color="danger">Processus</CBadge> },
}

export const CONTROL_STATUS = {
  'draft': { label: 'Brouillon', value: 'draft', badge: () => <CBadge color="warning">Brouillon</CBadge> },
  'underApproval': { label: 'En cours d\'approbation', value: 'underApproval', badge: () => <CBadge color="info">En cours d&apos;approbation</CBadge> },
  'inEffect': { label: 'En vigueur', value: 'inEffect', badge: () => <CBadge color="success">En vigueur</CBadge> },
  'archived': { label: 'Archivée', value: 'archived', badge: () => <CBadge color="danger">Archivée</CBadge> }
}

export const CHECKPOINT_CONTROL_EVALUATION_STATUS = {
  'toDo': { label: 'à faire', value: 'toDo', badge: () => <CBadge color="info">à faire</CBadge> },
  'inProgess': { label: 'En cours', value: 'inProgess', badge: () => <CBadge color="warning">En cours</CBadge> },
  'completed': { label: 'Réalisée', value: 'completed', badge: () => <CBadge color="success">Réalisée</CBadge> }
}

export const RISK_STATUS_ASSESSMENT_VERSION = {
  'draft': { label: 'Brouillon', value: 'draft', badge: () => <CBadge color="warning">Brouillon</CBadge> },
  'underApproval': { label: 'En cours d\'approbation', value: 'underApproval', badge: () => <CBadge color="info">En cours d&apos;approbation</CBadge> },
  'inEffect': { label: 'En vigueur', value: 'inEffect', badge: () => <CBadge color="success">En vigueur</CBadge> },
  'archived': { label: 'Archivée', value: 'archived', badge: () => <CBadge color="danger">Archivée</CBadge> }
}

export const INCIDENT_STATUS_VERSION = {
  'draft': { label: 'Brouillon', value: 'draft', badge: () => <CBadge color="warning">Brouillon</CBadge> },
  'underApproval': { label: 'En cours d\'approbation', value: 'underApproval', badge: () => <CBadge color="info">En cours d&apos;approbation</CBadge> },
  'inEffect': { label: 'En vigueur', value: 'inEffect', badge: () => <CBadge color="success">En vigueur</CBadge> },
  'archived': { label: 'Archivée', value: 'archived', badge: () => <CBadge color="danger">Archivée</CBadge> }
}

export const INDICATOR_STATUS_VERSION = {
  'draft': { label: 'Brouillon', value: 'draft', badge: () => <CBadge color="warning">Brouillon</CBadge> },
  'underApproval': { label: 'En cours d\'approbation', value: 'underApproval', badge: () => <CBadge color="info">En cours d&apos;approbation</CBadge> },
  'inEffect': { label: 'En vigueur', value: 'inEffect', badge: () => <CBadge color="success">En vigueur</CBadge> },
  'archived': { label: 'Archivée', value: 'archived', badge: () => <CBadge color="danger">Archivée</CBadge> }
}

export const STATUT_RISK_ACTION = {
  'toDo': { label: 'à faire', value: 'toDo', badge: () => <CBadge color="info">à faire</CBadge> },
  'inProgress': { label: 'en cours', value: 'inProgress', badge: () => <CBadge color="warning">En cours</CBadge> },
  'toValidate': { label: 'à valider', value: 'toValidate', badge: () => <CBadge color="success">A valider</CBadge> },
  'correction': { label: 'correction', value: 'correction', badge: () => <CBadge color="danger">Correction</CBadge> },
  'closed': { label: 'terminée', value: 'closed', badge: () => <CBadge color="dark">Terminée</CBadge> }
}

export const RESOLUTION_STATE = {
  'resolu': { label: 'Résolu', value: 'resolu', badge: () => <CBadge color="success">Résolu</CBadge> },
  'nonResolu': { label: 'Non résolu', value: 'nonResolu', badge: () => <CBadge color="danger">Non résolu</CBadge> },
}

export const STATUT_RH_EVALUATION = {
  'success': { label: 'Succès', value: 'success', badge: () => <CBadge color="success">Succès</CBadge> },
  'failure': { label: 'Echec', value: 'failure', badge: () => <CBadge color="danger">Echec</CBadge> },
  'notDefined': { label: 'Non défini', value: 'notDefined', badge: () => <CBadge color="info">Non défini</CBadge> },
}

export const INCIDENT_MAIN_STATUS = {
  'todo': { label: 'Analyse à faire', value: 'todo', badge: () => <CBadge color="danger">Analyse à faire</CBadge> },
  'inProgress': { label: 'Analyse en cours', value: 'inProgress', badge: () => <CBadge color="warning">Analyse en cours</CBadge> },
  'done': { label: 'Analyse terminée', value: 'done', badge: () => <CBadge style={{ '--cui-badge-color': '#819a91', 'background': 'yellow' }}>Analyse terminée</CBadge> },
  'planned': { label: 'Contrôles planifiés', value: 'planned', badge: () => <CBadge color="success">Contrôles planifiés</CBadge> },
}


export const COMMON_VERSION_STATUS = {
  'draft': { label: 'Brouillon', value: 'draft', badge: () => <CBadge color="warning">Brouillon</CBadge> },
  'underApproval': { label: 'En cours d\'approbation', value: 'underApproval', badge: () => <CBadge color="info">En cours d&apos;approbation</CBadge> },
  'inEffect': { label: 'En vigueur', value: 'inEffect', badge: () => <CBadge color="success">En vigueur</CBadge> },
  'archived': { label: 'Archivée', value: 'archived', badge: () => <CBadge color="danger">Archivée</CBadge> }
}



export const TYPE_ACTION_DASHBOARD = {
  'compliance': 'Mise en conformité',
  'nextComliance': 'Action suite mise en conformité',
  'EvaluationControlRisk': 'Contrôle',
  'nextEvaluationControlRisk': 'Action suite contrôle',
}

export const SESSION_STATUS = {
  'toBescheduled': { label: 'A planifier', value: 'toBescheduled', badge: () => <CBadge color="info">A planifier</CBadge> },
  'scheduled': { label: 'Planifiée', value: 'scheduled', badge: () => <CBadge color="warning">Planifiée</CBadge> },
  'inProgress': { label: 'En cours de réalisation', value: 'inProgress', badge: () => <CBadge color="primary">En cours de réalisation</CBadge> },
  'completed': { label: 'Réalisée', value: 'completed', badge: () => <CBadge color="success">Réalisée</CBadge> },
  'canceled': { label: 'Annulée', value: 'canceled', badge: () => <CBadge color="danger">Annulée</CBadge> },
}

export const SESSION_REGISTRATION_STATUS = {
  'success': { label: 'Succès', value: 'success', badge: () => <CBadge color="success">Succès</CBadge> },
  'failure': { label: 'Echec', value: 'failure', badge: () => <CBadge color="danger">Echec</CBadge> },
  'excused': { label: 'Excusé', value: 'excused', badge: () => <CBadge color="info">Excusé</CBadge> },
  'unexcused': { label: 'Non excusé', value: 'unexcused', badge: () => <CBadge color="warning">Non excusé</CBadge> },
}
